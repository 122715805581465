import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/workspace/src/components/MainLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Privacy policy`}</h2>
    <p><strong parentName="p">{`In osservanza delle norme previste dal Regolamento UE 2016/679, noto anche come GDPR (General Data Protection Regulation), si rende noto che:`}</strong></p>
    <p>{`Questo sito non raccoglie dati personali. L’invio facoltativo e volontario di e-mail agli indirizzi presenti nella pagina dei contatti comporta l’acquisizione dell’indirizzo mail del mittente che verrà utilizzato solo per l’eventuale risposta e non verrà comunicato a terzi.`}</p>
    <p>{`Questo sito si avvale del servizio Google Analytics della società Google, Inc. (di seguito "Google") per la generazione di statistiche sull'utilizzo del sito; Google Analytics utilizza cookie (non di terze parti) che non memorizzano dati personali.`}</p>
    <p>{`I cookies generati da questo servizio sono di tipo Analytics (quindi considerati cookies tecnici a tutti gli effetti) e raccolgono dati in forma aggregata circa gli accessi alle pagine che compongono questo sito web. Gli indirizzi IP dei visitatori sono anonimizzati e comunque i dati raccolti non sono utilizzati per attività di profilazione e/o per l’invio di messaggi pubblicitari mirati.`}</p>
    <h2>{`Cookie Policy`}</h2>
    <p>{`Questo sito utilizza cookie di sessione e cookie analitici per raccogliere informazioni, in forma aggregata e anonima, sul numero degli utenti e su come questi visitano il sito stesso.`}</p>
    <p>{`Per ulteriori informazioni sui cookie, consulta il sito del Garante per la protezione dei dati personali.`}</p>
    <p><strong parentName="p">{` Cookie non indispensabili e di terze parti - Cookie approvati dal gestore del sito che tuttavia possono essere disattivati. `}</strong></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Google Analytics - Questo sito si avvale del servizio Google Analytics della società Google, Inc. (di seguito "Google") per la generazione di statistiche sull'utilizzo del sito; Google Analytics utilizza cookie (non di terze parti) che non memorizzano dati personali.`}</p>
        <p parentName="li">{`I cookies generati da questo servizio sono di tipo Analytics (quindi considerati cookies tecnici a tutti gli effetti) e raccolgono dati in forma aggregata circa gli accessi alle pagine che compongono questo sito web. Gli indirizzi IP dei visitatori sono anonimizzati e comunque i dati raccolti non sono utilizzati per attività di profilazione e/o per l’invio di messaggi pubblicitari mirati.`}</p>
        <p parentName="li">{`Le informazioni ricavabili dai cookie sull'utilizzo del sito web da parte degli utenti (compresi gli indirizzi IP) verranno trasmesse dal browser dell'utente a Google, con sede a 1600 Amphitheatre Parkway, Mountain View, CA 94043, Stati Uniti, e depositate presso i server della società stessa. Secondo i termini di servizio termini di servizio in essere, Google utilizzerà queste informazioni, in qualità di titolare autonomo del trattamento, allo scopo di tracciare e esaminare l'utilizzo del sito web, compilare report sulle attività del sito ad uso degli operatori del sito stesso e fornire altri servizi relativi alle attività del sito web, alle modalità di connessione (mobile, pc, browser utilizzato etc) e alle modalità di ricerca e raggiungimento delle pagine del portale. Google può anche trasferire queste informazioni a terzi ove ciò sia imposto dalla legge o laddove tali terzi trattino le suddette informazioni per conto di Google. Google non assocerà gli indirizzi IP a nessun altro dato posseduto da Google. Per consultare l'informativa privacy della società Google, relativa al servizio Google Analytics, si prega di visitare il sito Internet `}<a parentName="p" {...{
            "href": "http://www.google.com/intl/en/analytics/privacyoverview.html"
          }}>{`http://www.google.com/intl/en/analytics/privacyoverview.html`}</a>{`. Per conoscere le norme sulla privacy di Google, si invita a vistare il sito Internet `}<a parentName="p" {...{
            "href": "http://www.google.com/intl/it/privacy/privacy-policy.html"
          }}>{`http://www.google.com/intl/it/privacy/privacy-policy.html`}</a>{`. Utilizzando questo sito web, si acconsente al trattamento dei propri dati da parte di Google per le modalità e i fini sopra indicati.`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      